// @import url(https://db.onlinewebfonts.com/c/d3ef53581d67cbf0c1e9a620b9986db0?family=Abadi+MT+W04+Cond+Extra+Bold);
@font-face {
  font-family: "AbadiBold";
  src: url("./assets/fonts/Abadi/Abadi-MT-Condensed-Extra-Bold-Regular-Font.ttf");
}
@font-face {
  font-family: "Abadi";
  src: url("./assets/fonts/Abadi/fonnts.com-Abadi_MT_Pro_Bold.otf");
}
// variables
$Abadi: "Abadi", Arial, Helvetica, sans-serif;
$primary-color: #f05c24;
$yellow: #f1b71c;
$bgGray: #272727;
$bgBlack: #17181d;
$GrayPanel: #32353f;
$Gray2: #23252d;

.align-block-middle {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
a {
  color: $yellow;
  transition: all 0.3s ease-in;
  &:hover {
    color: $primary-color;
    transition: all 0.3s ease-out;
  }
}
.downloadButton {
  font-weight: 600;
  color: $yellow;
  transition: all 0.3s ease-in;
  &:hover {
    color: $primary-color;
    transition: all 0.3s ease-out;
  }
}

b {
  font-weight: 600;
}

.learnMore {
  background-color: $Gray2;
  font-size: 0.8rem;
  padding: 0.1rem 1rem;
  border-radius: 1.5rem;
  color: $yellow;
  margin-left: auto;
  display: block;
  float: right;
  &:hover {
    color: #ffffff;
    background-color: $GrayPanel;
  }
}

// MUI
.MuiFormControl-root {
  .MuiFormLabel-root {
    color: #ffffff;
    &.Mui-focused {
      color: $yellow;
    }
  }
  .MuiInputBase-root {
    color: #ffffff;
    &::before {
      border-bottom-color: #cccccc;
    }
    &::after {
      border-color: $yellow;
    }
    &:hover:not(.Mui-disabled, .Mui-error):before {
      border-bottom-color: #ffffff;
    }
    .MuiOutlinedInput-notchedOutline {
      border-color: #ffffff;
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $yellow;
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: $yellow;
    }
  }
}
.inputFile input {
  padding: 0.9rem 0;
  &::-webkit-file-upload-button {
    background: $GrayPanel;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    outline: none;
    padding: 10px 25px;
    text-transform: none;
    letter-spacing: 1px;
    transition: all 1s ease;
    float: right;
    margin-top: -0.5rem;
  }
  &::-webkit-file-upload-button:hover {
    background: $Gray2;
    color: $primary-color;
  }
}
// .MuiAlert-filled,
// .MuiSnackbar-root {
//   background-color: $bgBlack;
// }
.popOver {
  .MuiPopover-paper {
    background-color: $GrayPanel;
    padding: 1rem 0.5rem 1.5rem;
    color: #ffffff;
    border-radius: 5px;
  }
  .popUpFooter {
    display: flex;
    flex-direction: column;
    max-width: 250px;
    h5 {
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
      line-height: 1;
    }
    p {
      font-size: 0.7rem;
      line-height: 1.5;
      margin-right: 0.5rem;
    }
    svg {
      font-size: 1rem;
      cursor: pointer;
      position: absolute;
      bottom: 7px;
      right: 7px;
    }
  }
}
.modalContainer {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 85vh;
  width: 55vw;
  border: 2px solid $GrayPanel;
  padding: 2rem;
  overflow: auto;
  background-color: $bgBlack;
  font-size: 1rem;
  color: #ffffff;
  > h5 {
    margin-bottom: 2rem;
  }
  > .MuiSvgIcon-root {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
  }
  .contact-form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .field-container {
    display: flex;
    width: 47%;
    flex-direction: column;
    margin-bottom: 2rem;
    &:nth-last-child(2) {
      width: 100%;
    }
    input {
      font-size: 0.9rem;
    }
    label {
      line-height: 1;
      font-size: 1rem;
      font-weight: 600;
    }
  }
  .form-submit-btn {
    width: 100%;
    text-align: center;
  }
  &.modalContact {
    .contact-form .field-container {
      width: 100%;
    }
  }
}
/* Firefox */
input[type="number"] {
  // appearance: "";
  appearance: textfield;
  -moz-appearance: textfield;
}

html {
  scroll-behavior: smooth;
}
body {
  letter-spacing: 1px;
  background-color: $bgBlack;
  font-size: 1.1rem;
}

.justify-content-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.flex-reverse {
  display: flex !important;
  flex-direction: row-reverse !important;
}

.cursor-pointer {
  cursor: pointer;
}

h5 {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 1.1rem;
}

h6 {
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

p {
  font-size: 1.1rem;
}

.mainTitle {
  margin-bottom: 3rem;
  position: relative;
  .title {
    font-size: 2.6rem;
    line-height: normal;
    color: $primary-color;
    line-height: 1.5;
  }
  p {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.pt-60 {
  padding-top: 60px !important;
}

.sectionContainer {
  background-color: $bgBlack;
  padding: 6rem 0;
  position: relative;
  z-index: 1;
  color: #ffffff;
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &.spaceAround {
      justify-content: space-around;
    }
    &.containerBlock {
      display: block;
    }
  }
}
section.bgBlack {
  background-color: $bgGray;
}

// button
.btn {
  background-color: $bgGray;
  padding: 0.8rem 2rem;
  color: #ffffff;
  border-radius: 2rem;
  border: 1px solid #3d3d3d;
  box-shadow: 0 3px 5px 3px $bgBlack;
  font-weight: 600;
  letter-spacing: 2px;
  margin: 1rem auto auto;
  &:hover {
    color: $yellow;
  }
}
.bgBlack {
  .btn {
    background-color: $bgBlack;
    box-shadow: 0 3px 5px 3px $bgGray;
  }
}
section.bgDark {
  background-color: #000 !important;
}

.hideArrows {
  .controls {
    display: none;
  }
}

section {
  &.services-intro {
    .container {
      display: flex;

      // align-items: flex-start;
      // align-items: stretch;
    }
    .rowReverse {
      flex-direction: row-reverse;
      .right {
        padding: 0 5% 0 0;
      }
    }
    .copy {
      color: #ffffff;
      margin-bottom: 1rem;
      b {
        font-weight: 700;
      }
      span {
        font-weight: 600;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    .col.left {
      width: 50%;
    }
    .right {
      padding: 0% 0 0% 5%;
      display: flex;
      flex-direction: column;
      // justify-content: center;
    }
    .media {
      background-color: transparent;
      position: relative;
      .image {
        height: 100%;

        width: 100%;
        object-fit: cover;
      }
      svg {
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        height: 33px;
        width: 33px;
        cursor: pointer;
      }
    }
    .imageDetails {
      position: absolute;
      bottom: -37px;
      color: #ffffff;
      font-style: italic;
      font-size: 1rem;
    }
    ul {
      color: #ffffff;
      padding-left: 1.5rem;
      li {
        list-style: disc;
        margin-bottom: 0.7rem;
      }
    }
  }
}

.image-carosul-wrapper {
  height: 100%;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}

.ltw-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .ltw-wrapper {
    background: #000;
    padding: 20px;
    display: flex;
    width: 32%;
    flex-direction: column;
    .ltw-quote {
      font-style: italic;
      margin-bottom: 5rem;
    }
    .ltw-speakerContainer {
      display: flex;
      gap: 20px;
      margin-top: auto;
      text-align: end;
      align-items: center;
      .location {
        margin-right: auto;
      }
      .ltw-speakerName {
        font-weight: 600;
      }
      .ltw-speakersub {
        font-size: 12px;
      }
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}

// flip box
.service-box {
  position: relative;
  overflow: hidden;
  perspective: 1000px;
  -webkit-perspective: 1000px;
  width: 23%;
  margin-bottom: 2.5%;
  &.three {
    width: 32%;
  }
  .service-icon,
  .service-content {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 320px;
    padding: 1rem;
    text-align: center;
    transition: all 0.5s ease;
  }
  &.bgImage .service-icon::before {
    background-color: transparent;
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 9%,
      rgba(0, 57, 69, 0.1) 50%,
      rgba(0, 0, 0, 0.9) 80%,
      rgb(0, 0, 0) 100%
    );
  }
  .service-icon {
    align-items: flex-end;
    background-size: cover;
    background-position: center center;
    position: relative;
    &::before {
      content: "";
      display: block;
      background-color: rgb(0, 0, 0, 70%);
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      left: 0;
    }
    h3 {
      font-size: 1.1rem;
      font-weight: 600;
      color: #fff;
      margin-bottom: 0;
      text-transform: uppercase;
      position: relative;
    }
    > svg {
      position: absolute;
      bottom: 0.7rem;
      right: 0.7rem;
      width: 22px;
      display: none;
    }
    &.iconsFront {
      flex-direction: column;
      align-items: center;
      img {
        height: 70px;
      }
    }
  }
  .service-content {
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    font-weight: 600;
    background-color: $bgBlack;
    color: $primary-color;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transform: translateY(110px) rotateX(-90deg);
    -moz-transform: translateY(110px) rotateX(-90deg);
    -ms-transform: translateY(110px) rotateX(-90deg);
    -o-transform: translateY(110px) rotateX(-90deg);
    transform: translateY(110px) rotateX(-90deg);
    img {
      height: 65px;
      margin-bottom: 1.5rem;
    }
    svg {
      height: 65px;
      margin-bottom: 1.5rem;
      path {
        fill: $primary-color;
      }
    }
    h3 {
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    a {
      margin-top: 1rem;
      color: #ffffff;
      background-color: $Gray2;
      font-size: 0.8rem;
      padding: 0.1rem 1rem;
      border-radius: 1.5rem;
      &:hover {
        background-color: $GrayPanel;
        color: $yellow;
      }
    }
    ul {
      text-align: left;
      padding-left: 1rem;
      font-size: 1rem;
      li {
        list-style: disc;
        margin-bottom: 1rem;
      }
    }
  }
  &:hover {
    .service-icon {
      opacity: 0;
      -webkit-transform: translateY(-110px) rotateX(90deg);
      -moz-transform: translateY(-110px) rotateX(90deg);
      -ms-transform: translateY(-110px) rotateX(90deg);
      -o-transform: translateY(-110px) rotateX(90deg);
      transform: translateY(-110px) rotateX(90deg);
    }
    .service-content {
      opacity: 1;
      -webkit-transform: rotateX(0);
      -moz-transform: rotateX(0);
      -ms-transform: rotateX(0);
      -o-transform: rotateX(0);
      transform: rotateX(0);
    }
  }
}
.collageFlipBox {
  .multipleItems {
    width: 33%;
    .service-box {
      width: 100%;
    }
  }
  .service-box {
    width: 33%;
    margin-bottom: 7px;
  }
  img {
    width: 33%;
    margin-bottom: 7px;
    object-fit: cover;
  }
  .service-icon {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h6 svg {
      height: 80px;
    }
    h3 {
      font-size: 1.5rem;
      margin-top: 1rem;
    }
  }
  .service-content {
    background-color: #272727;
  }
}

// icons small black - D4S
.iconSmallBlock {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1.5rem;
  > img {
    height: 40px;
  }
  ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 1rem 1.5rem;
      background-color: $GrayPanel;
      color: #ffffff;
      border-radius: 5px;
      transition: all 0.4s linear;
      &:hover {
        background-color: $Gray2;
        transition: all 0.4s linear;
        svg path {
          transition: all 0.4s linear;
          fill: $primary-color;
        }
      }
      svg {
        height: 40px;
        width: 40px;
        margin-bottom: 0.5rem;
        path {
          transition: all 0.4s linear;
          fill: $yellow;
        }
      }
    }
  }
}

// title paragraph block
.titleParagraphBlock {
  width: 47%;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  &.yellowBordered {
    h5,
    p {
      border-left: 4px solid $yellow;
      padding-left: 0.7rem;
    }
    h5 {
      text-transform: uppercase;
      line-height: 1.2;
    }
    p {
      border-left-color: transparent;
    }
  }
  h5 {
    margin-bottom: 1rem;
  }
}

// Image content block
.ImageContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.rowReverse {
    flex-direction: row-reverse;
  }
  &.imageContained {
    .imageBackground {
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .imageBackground {
    width: 50%;
    height: 100%;
    background-size: cover;
    background-position: 46%;
    cursor: pointer;
    position: relative;
    img {
      display: none;
    }
    svg {
      position: absolute;
      bottom: 0.7rem;
      right: 0.7rem;
      height: 33px;
      width: 33px;
    }
  }
  &.ModalImageContent {
    .imageBackground {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom right;
    }
  }
  p {
    margin-bottom: 1.3rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .content {
    width: 47%;
    padding: 0 1rem;
    span {
      font-weight: 600;
    }
    b {
      font-weight: 600;
    }
  }
  ul {
    padding-left: 1.5rem;
    li {
      list-style-type: disc;
      margin-bottom: 0.5rem;
    }
  }
  h5 {
    font-size: 2.4rem;
    color: $primary-color;
    line-height: 1.5;
    margin-bottom: 1.5rem;
    font-weight: 400;
  }
}

// Text Centered Block - Service Provider
.textCenteredBlock {
  text-align: center;
  h4 {
    color: $primary-color;
    font-size: 2.4rem;
    margin-bottom: 2rem;
  }
}

// hover Image content Slide up - Government
.bgBlack .hoverSlideUp h5 {
  background-color: $bgBlack;
}
.hoverSlideUp {
  position: relative;
  background-color: lightGrey;
  overflow-y: hidden;
  box-shadow: 0.1rem 0.1rem 1rem rgba(0, 0, 0, 0.1);
  width: 24.75%;
  margin-bottom: 0.51%;
  height: 250px;
  &.two {
    width: 48%;
    height: 350px;
    margin-bottom: 2.5rem;
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  h5 {
    margin: 0;
    display: block;
    background-color: $bgGray;
    padding: 1rem;
    transform: translate3d(0, -95%, 0);
    transition: transform 5ms;
  }

  &:hover,
  &:focus {
    .item__overlay {
      transform: translate3d(0, 0, 0);

      h5 {
        transform: translate3d(0, 0, 0);
      }

      .item__body {
        opacity: 1;
      }
    }
  }
  .item__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    transition: transform 700ms;
    background-color: $bgBlack;
    transform: translate3d(0, 100%, 0);
  }

  .item__body {
    flex-grow: 1;
    padding: 1rem;
    opacity: 0;
    transition: opacity 700ms 300ms;

    p {
      margin: 0;
    }
  }
}

// Accordion panel - Manufacturing
.accordionPanel {
  max-width: 100%;
  &.bgBlack {
    .MuiAccordion-root.MuiPaper-elevation {
      background-color: $bgBlack;
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    > * {
      width: 33%;
      max-width: 33%;
    }
    h5 {
      white-space: nowrap;
      margin-bottom: 0;
    }
    h6 {
      text-transform: uppercase;
      font-size: 0.7rem;
      opacity: 0.6;
      font-weight: 500;
      letter-spacing: 2px;
      margin-bottom: 0;
      line-height: 1.1;
    }
    p {
      font-size: 0.9rem;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .content {
    display: flex;
    justify-content: space-around;
    padding-bottom: 1rem;
    > div {
      width: 45%;
    }
    h5 {
      font-size: 0.9rem;
      margin-bottom: 0.3rem;
      color: #ffffff;
    }
    p,
    li {
      font-size: 0.9rem;
      line-height: 1.7;
    }
    ul {
      padding-left: 1.1rem;
    }
    li {
      list-style: disc;
    }
  }
  .MuiAccordionSummary-gutters {
    h5 {
      margin-bottom: 0;
      color: $yellow;
      text-transform: uppercase;
      font-size: 1.1rem;
    }
    .MuiSvgIcon-root {
      fill: #ffffff;
    }
    &:hover {
      h5 {
        color: $primary-color;
      }
      .MuiSvgIcon-root {
        fill: $yellow;
      }
    }
  }
  .MuiAccordionSummary-content {
    margin: 1.5rem 0;
  }
  .MuiAccordion-root.MuiPaper-elevation {
    background-color: $bgGray;
    color: #ffffff;
    width: 100%;
    &::before {
      background-color: rgb(255 255 255 / 20%);
    }
  }
}

// header
header.primary {
  z-index: 1200;
  // background-image: linear-gradient(
  //   180deg,
  //   #000,
  //   rgb(0, 0, 0, 70%),
  //   transparent
  // );
  background-color: #000000;
  top: 0;
  padding: 0;
  .menu-header-menu-container {
    position: static;
    transform: none;
  }
  .logo img {
    height: 49px;
    width: auto;
  }
}
#menu-header-menu li {
  vertical-align: top;
}

#menu-header-menu {
  li {
    .sub-menu {
      right: 0;
      padding-right: 6vw;
      li {
        position: relative;
        button:hover {
          color: $yellow;
          opacity: 1;
        }
      }
    }
    &.menu-item-has-children:hover {
      color: $yellow;
    }
    &.dropdownCentered {
      position: relative;
      .sub-menu {
        left: 0;
        text-align: left;
        li {
          position: relative;
        }
        &::before {
          width: 100vw;
          background-color: #131415;
          position: absolute;
          left: -97%;
          top: 0;
          right: auto;
        }
      }
    }
  }
}

.scrolled header.primary #main-logo {
  height: 78px;
}
header.primary .hamburger-menu {
  right: 8px;
  top: 7px;
}

.hamburger-menu {
  display: none;
}

.homeScreenList {
  margin-bottom: 0;
  padding-left: 2rem;
  li {
    margin-bottom: 0.7rem;
    list-style: disc;
    padding-left: 0.5rem;
    font-size: 1.1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

hgroup.view-detect.viewed .section-subtitle {
  color: #f15e27;
  font-size: 2.7rem;
  text-transform: none;
  line-height: normal;
}
.imageBlockContainer {
  section {
    &.alignment--image_right:nth-child(even) .inner {
      flex-direction: row-reverse;
    }
  }
}
// value props
.valuePropsContainer {
  & + .valuePropsContainer {
    section.bgImageBlock {
      padding-top: 6rem;
    }
  }
  section.bgImageBlock {
    padding-top: 190px;
    padding-bottom: 7rem;
    background-color: $bgBlack;
  }
  // .image.paroller {
  //   position: absolute;
  //   width: 100%;
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   height: 130%;
  //   top: -20%;
  //   background-position: center center;
  // }
}

section.imageContained .media .image {
  position: absolute;
  object-fit: contain;
}
// hover Content visible (BigData)
.hoverContent {
  width: 23%;
  background-image: linear-gradient(-45deg, #194a6e, #051927);
  padding: 2.5rem 1rem;
  text-align: center;
  border-radius: 1rem;
  position: relative;
  height: 285px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 20px #181818;
  overflow: hidden;
  &.threeColumn {
    width: 30%;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &.hoverBig {
    height: 350px;
  }
  &:hover {
    h3 {
      height: fit-content;
      font-size: 3.3rem;
      margin-bottom: 0.5rem;
    }
    p {
      height: fit-content;
      max-height: 500px;
      opacity: 1;
      transition: max-height 2s linear, opacity 0.5s linear;
    }
    a {
      height: fit-content;
      opacity: 1;
      transition: opacity 0.5s linear;
    }
    h5 {
      height: fit-content;
      transition: all 0.4s linear;
      font-size: 1.2rem;
    }
  }
  h5 {
    margin-block-end: 0;
    margin-block-start: 0;
    transition: all 0.4s linear;
    margin-bottom: 0.5rem;
    font-size: 1.6rem;
  }
  h3 {
    margin-block-end: 0;
    margin-block-start: 0;
    font-family: $Abadi;
    margin-bottom: 0;
    font-size: 3.5rem;
    transition: all 0.4s linear;
    line-height: 1;
  }
  p {
    margin-block-end: 0;
    margin-block-start: 0;
    line-height: 1.5;
    font-size: 1rem;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.9s linear, opacity 0.5s linear;
  }
  a {
    color: #ffffff;
    background-color: $Gray2;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    position: absolute;
    bottom: 0.5rem;
    right: 1rem;
    font-size: 0.7rem;
    font-weight: 600;
    line-height: 1;
    opacity: 0;
    transition: all 0.5s linear;
    &:hover {
      background-color: $GrayPanel;
      color: $yellow;
      transition: all 0.5s linear;
    }
  }
  svg {
    display: none;
  }
}
// Carousel text Image
.carouselTitleNav {
  position: relative;
  background-color: $bgGray;
  min-height: 85px;
  // .flickity-viewport {
  //    height: auto !important;
  // }
  &.navBig {
    min-height: 159px;
    .flickity-slider > div {
      margin: 1rem 2.5rem;
    }
  }
  .container {
    display: block;
  }
  .flickity-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    transform: none !important;
    padding: 0.5rem;
    > div {
      position: unset !important;
      cursor: pointer;
      font-weight: 600;
      font-size: 1.2rem;
      letter-spacing: 1px;
      padding-bottom: 0.3rem;
      margin: 1rem 1.3rem;
      color: #b6b6b6;
      border-bottom: 3px solid transparent;
      min-height: 37px;
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        color: #ffffff;
      }
      &.is-selected {
        border-bottom-color: $yellow;
        color: #ffffff;
      }
    }
  }
  .flickity-button {
    display: none;
  }
}

.wrap-value-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

// Slick

.slickSolution {
  background-color: #000000;
  .sliderNav {
    cursor: pointer;
    font-weight: 600;
    font-size: 1.2rem;
    color: #b6b6b6;
    .slick-slide {
      div[tabindex] {
        border-bottom: 3px solid transparent;
        width: fit-content !important;
      }
      &.slick-current {
        color: #ffffff;
        div[tabindex] {
          border-color: $yellow;
        }
      }
      &:hover {
        color: #ffffff;
      }
    }
  }
  h5 {
    font-size: 1.41rem;
  }
  a {
    font-weight: 600;
  }
  .links {
    background-color: $Gray2;
    font-size: 0.8rem;
    padding: 0.1rem 1rem;
    border-radius: 1.5rem;
    color: $yellow;
    margin-top: 1rem;
    margin-left: auto;
    &:hover {
      color: #ffffff;
      background-color: $GrayPanel;
    }
  }
  .sliderKeywords {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.5rem;
    li {
      background-color: #272727;
      border-radius: 20px;
      font-size: 0.9rem;
      line-height: 1.3;
      margin-bottom: 0.8rem;
      margin-right: 1rem;
      padding: 0.3rem 1rem;
    }
  }
  .carousalList {
    margin-top: 1rem;
    margin-left: 2rem;
    li {
      list-style-type: disc;
    }
  }
}

.SlickVerticalSlider {
  border: 1px solid #434343;
  border-radius: 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .sliderNav {
    width: 35%;
    border-radius: 2rem 0 0 2rem;
    background-color: #272727;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    margin-bottom: 0;
    padding-bottom: 0;
    .slick-list {
      width: 100%;
    }
    .slick-slide {
      width: 100% !important;
      text-align: right;
      &:last-child div[tabindex] {
        margin-bottom: 0;
      }
      div[tabindex] {
        margin: 0 2.5rem 2.5rem;
      }
    }
  }
  .sliderMain {
    width: 65%;
    padding: 2rem;
    margin-bottom: 0;
    div[tabindex] {
      display: flex !important;
      flex-direction: column;
    }
    img {
      margin-bottom: 2rem;
    }
  }
}

.slickTitleNav {
  background-color: $bgGray;
  margin-bottom: 5rem;
  .sliderNav {
    padding: 1.5rem 0;
    margin-bottom: 0;
  }
  .slick-track {
    display: flex;
    justify-content: space-between;
    &::before,
    &::after {
      display: none;
    }
    > div[tabindex] {
      width: auto !important;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .slick-slide {
    div[tabindex] {
      margin-right: 1.3rem;
    }
  }
}
.slickTitleCon {
  .slick-slide {
    div[tabindex] {
      display: flex !important;
      img {
        width: 40%;
        min-width: 40%;
        object-fit: cover;
      }
      .slideTitleContent {
        padding-left: 4.5rem;
        display: flex;
        flex-direction: column;
        .links {
          margin-left: auto;
        }
      }
    }
  }
}
.UniversitySlick {
  .imageText {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    background-color: $Gray2;
    img {
      width: 45%;
      object-fit: cover;
    }
  }
  .text {
    width: 50%;
    padding: 1rem;
    h5 {
      color: $primary-color;
      font-weight: 600;
      text-transform: uppercase;
    }
    p {
      font-style: italic;
    }
  }
  blockquote {
    width: 43%;
    margin-left: auto;
    text-align: right;
    h6 {
      margin-bottom: 0.3rem;
      font-size: 0.9rem;
    }
    p {
      font-size: 0.7rem;
      line-height: 1.7;
    }
  }
  .details {
    margin-bottom: 1rem;
  }
}

// text Button Block - HPC
.bgBlack .textButtonBlock {
  background-color: $bgBlack;
}
.textButtonBlock {
  background-color: $bgGray;
  padding: 2rem;
  border-radius: 1.5rem;
  width: 32%;
  display: flex;
  flex-direction: column;
  // &:first-child > a {
  //   background-image: linear-gradient(161deg, #051b3f, #0064aa);
  // }
  // &:last-child > a {
  //   background-image: linear-gradient(161deg, #2d1108, #982d0c);
  // }
  > p {
    margin-bottom: 2rem;
  }
  p a {
    font-size: inherit;
    color: $yellow;
    font-weight: 600;
  }
  > a {
    background-image: linear-gradient(161deg, #051b3f, #0064aa);
    // background-image: linear-gradient(161deg, #023b43, #056f7d);
    display: flex;
    color: #ffffff;
    justify-content: space-between;
    align-items: center;
    border-radius: 2rem;
    padding: 1rem 1.5rem;
    margin-top: auto;
    img {
      height: 31px;
    }
    span {
      background-color: $bgGray;
      font-size: 0.7rem;
      padding: 0 1rem;
      border-radius: 1.5rem;
    }
  }
}

// Title Image Content details - Education
.TitleImageContentBlock {
  .sectionContent {
    margin-bottom: 4rem;
    // &:last-child .textBlocks {
    //   flex-direction: row-reverse;
    // }
  }
  .sectionTitle {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1.3rem;
    text-transform: uppercase;
  }
  .textBlocks {
    .image {
      width: 27%;
      img {
        height: -webkit-fill-available;
        object-fit: cover;
      }
    }
    .content {
      width: 68%;
    }
    .blocks {
      margin-bottom: 2rem;
    }
    h5 {
      margin-bottom: 0.5rem;
    }
  }
}

// Text Title Box Colored Bg
.textBoxColored {
  width: 49%;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $bgGray;
  padding: 1rem;
  &.white {
    background-color: #d9d9d9;
    .content {
      color: $bgBlack;
    }
    a {
      color: $primary-color;
      &:hover {
        color: $bgBlack;
      }
    }
  }
  &.pink {
    background-color: #e7135d;
  }
  &.green {
    background-color: #227137;
  }
  &.gray {
    background-color: #202020;
  }
  img {
    width: 27%;
  }
  .content {
    width: 67%;
    // p {
    //   font-size: 1rem;
    //   line-height: 1.7;
    // }
  }
  a {
    text-align: right;
    display: block;
    color: $yellow;
    font-weight: 600;
    font-size: 0.9rem;
    &:hover {
      color: #ffffff;
    }
  }
}

// Gray Background Box's - Finance
.bgBlack .grayBox {
  background-color: $bgBlack;
  p {
    background-color: $bgGray;
  }
}
.grayBox {
  background-color: $bgGray;
  padding: 5px;
  border-radius: 15px;
  width: 48%;
  margin-bottom: 4%;
  display: flex;
  flex-flow: column;
  &.three {
    width: 32%;
  }
  h5 {
    padding: 1rem;
    line-height: 1.2;
    margin-bottom: 0;
  }
  p {
    background-color: $bgBlack;
    padding: 1rem;
    border-radius: 15px;
    flex: 1;
  }
}

// partners Logo and content Block - Finance
.partnersBlock {
  .partnersContainer {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #ffffff;
    border-width: 1px 0 0 1px;
    width: 100%;
    .titleParagraphBlock {
      margin: 0;
      padding: 1.5rem;
      transition: all 0.5s ease;
      h5 {
        transition: all 0.5s ease;
      }
      &:hover {
        background-color: $bgBlack;
        h5 {
          color: $yellow;
        }
      }
    }
    > div {
      border: 1px solid #ffffff;
      border-width: 0 1px 1px 0;
      &:first-child {
        width: 25%;
      }
      &.multipleBlocks {
        width: 75%;
        display: flex;
        flex-wrap: wrap;
        margin-left: auto;
        > div {
          width: 50%;
          border: 1px solid #ffffff;
          border-width: 0 0 1px 0;
          &:first-child {
            border-right-width: 1px;
          }
          &:last-child {
            width: 100%;
            border-bottom-width: 0;
          }
        }
      }
      &:last-child {
        width: 100%;
      }
    }
  }
  .logos {
    width: 27%;
    object-fit: cover;
  }
  .content,
  .moreContent {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    .titleParagraphBlock {
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  .moreContent {
    width: 100%;
    margin-top: 2rem;
  }
}

// slick Carousel text block
.slick-slider {
  padding-bottom: 3rem;
  margin-bottom: 2rem;
  .slick-next,
  .slick-prev {
    top: 100%;
    left: auto;
    z-index: 11;
    width: 50px;
    height: 50px;
    display: inline-block;
    background-color: #fff;
    border: 1px solid hsla(0, 0%, 100%, 0.1);
    -webkit-appearance: none;
    appearance: none;
    border-radius: 100%;
    overflow: hidden;
    transform-origin: center;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    cursor: pointer;
    &::before {
      content: "";
      display: block;
      background-image: url("./assets/img/icons/arrow-right.svg");
      background-size: 17px 13px;
      background-position: right center;
      background-repeat: no-repeat;
      will-change: all;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      width: 17px;
      height: 13px;
      margin: auto;
      transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
    }
    &:not(.slick-disabled):hover {
      background-color: $yellow;
      &::before {
        width: 10px;
      }
    }
  }
  .slick-disabled {
    cursor: not-allowed;
  }
  .slick-next {
    right: 0;
  }
  .slick-prev {
    right: 60px;
    &::before {
      background-position: right center;
      transform: rotate(180deg);
    }
  }
}
.slickContainer {
  position: relative;
  .playPause {
    position: absolute;
    right: 0;
    bottom: -58px;
    width: 41px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $bgGray;
    border-radius: 50%;
    color: #cccccc;
  }
}
.textCarouselSlick {
  padding: 0 70px;
  .slick-track {
    display: flex;
    .slick-slide {
      margin-left: 1rem;
      margin-right: 1rem;
      background-color: $bgGray;
      color: #ffffff;
      height: inherit;
      border-radius: 1.5rem;
    }
    b {
      font-weight: 600;
      color: $yellow;
    }
    h3 {
      font-size: 1.2rem;
      text-transform: uppercase;
      line-height: 1.3;
      margin-bottom: 1.5rem;
      font-weight: 600;
    }
    .slickCon {
      padding: 2rem;
    }
  }
  .slick-list {
    margin-left: -1rem;
    margin-right: -1rem;
    cursor: grab;
  }
  .slick-prev {
    right: auto;
    left: 0;
  }
  .slick-next,
  .slick-prev {
    top: 50%;
    // bottom: 0;
    // transform: none;
    // height: 100%;
    // border-radius: 0;
    // background-color: $bgBlack;
  }
}

.bgBlack {
  .slick-track .slick-slide,
  .slickContainer .playPause {
    background-color: $bgBlack;
  }
}

// home
.home-screen__step__content {
  position: absolute;
  bottom: 0;
  top: auto;
  height: auto;
  left: 0;
  right: 0;

  &.home-screen__step-1__content > h1 {
    padding: 4rem 8.84% 2.5rem;
    line-height: 1;
    font-weight: 600;
  }
  &.home-screen__step-1__content > h2 {
    padding: 0 13% 4rem 8.84%;
    font-size: 1.5rem;
    min-height: 273px;
  }
  .home-screen__step-1__content__subtitle {
    transform: none;
    font-size: 1.5rem;
    line-height: 1.3;
    max-width: unset;
  }
}
div {
  .bmpui-ui-controlbar,
  .bmpui-ui-recommendation-overlay,
  .bmpui-ui-playbacktoggle-overlay {
    display: none;
  }
  .bitmovinplayer-container video,
  .bitmovinplayer-ad-container video,
  .home-screen__step-1__bg__video {
    object-fit: cover;
    max-width: 100vw;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
  }
  .bitmovinplayer-container,
  .bitmovinplayer-ad-container {
    background-color: transparent;
  }
}
#home-landing__bg .home-screen__step-1__bg__video {
  visibility: visible;
}
#home-landing__content {
  // background-color: rgb(0 0 0 / 70%);
  right: 0;
  height: 100%;
  #home-landing__content__text__heading {
    margin-right: 0;
    display: block;
    height: 100%;
  }
  #home-landing__content__text {
    width: 100%;
    padding: 0;
    .home-pagination__nav__item__btn {
      width: 100%;
      position: absolute;
      height: 100%;
      &::after,
      &::before {
        width: 0;
        height: 0;
      }
    }
    h1,
    .mainPara,
    ul {
      padding-left: 8.84%;
      padding-right: 9.66%;
    }
    h1 {
      font-size: 3rem;
      font-weight: 600;
      margin-bottom: 2rem;
      line-height: 1;
      span {
        color: $primary-color;
      }
    }
    .mainPara {
      margin-bottom: 3rem;
    }
    p {
      font-size: 1.5rem;
      letter-spacing: 1px;
    }
    ul {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      // background-image: linear-gradient(
      //   360deg,
      //   #000,
      //   rgb(0 0 0 / 90%),
      //   transparent
      // );
      padding-top: 5rem;
      padding-bottom: 3rem;
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 2.6%;
      z-index: 3;
      li {
        display: flex;
        align-items: center;
        margin-right: 5rem;
        position: relative;
        color: #ffffff;
        flex-direction: column;
        &:hover {
          color: $yellow;
          svg {
            path,
            circle {
              fill: $yellow;
            }
          }
        }
      }
      svg {
        height: 37px;
        margin-bottom: 1rem;
      }
      p {
        font-size: 1rem;
        line-height: 1.3;
      }
    }
  }
  #home-landing__content__text__bg {
    background-color: transparent;
  }
  .landingTextAlign {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    width: 50%;
    left: 7%;
  }
}
.home-pagination--small .home-pagination__nav__item--selected button {
  visibility: hidden;
}
#home-screen-1,
#home-screen-2,
#home-screen-3,
#home-screen-4 {
  .home-screen__step-2__bg {
    width: 84.58%;
    height: 81.11%;
    background-color: rgb(0, 0, 0, 0.95);
    left: 0;
    bottom: 0;
    top: auto;
  }
  .home-screen__step-2__img {
    left: 0;
    right: 0;
  }
  .home-screen__step__content {
    width: 50%;
    left: 7%;
    top: 60%;
    transform: translateY(-50%);
  }
  .home-screen__step-2__content {
    width: 84.58%;
    height: 82.3%;
    left: 0;
  }
}
#home-screen-3 {
  .home-screen__step-1__content {
    top: 75%;
    width: 85%;
  }
}
#home-screen- {
  &1 .home-screen__step-2__img {
    background-image: url("./assets/img/landingPage/HomePage_AIandML.jpg");
  }
  &2 .home-screen__step-2__img {
    background-image: url("./assets/img/landingPage/HomePage_BigData.jpg");
  }
  &3 .home-screen__step-2__img {
    background-image: url("./assets/img/landingPage/HomePage_GreenTransformation.jpg");
  }
  &4 .home-screen__step-2__img {
    background-image: url("./assets/img/landingPage/HomePage_ManagedServices.jpg");
  }
}
.home-screen__step__content__subtitle {
  max-width: unset;
}
.home-screen__step-2__content__inner__link__label {
  color: $yellow;
}
.no-touchevents .home-screen__step-2__content__inner__link:hover::after {
  background-color: $yellow;
}
#home-pagination {
  background-color: $bgGray;
  bottom: 0;
  left: 0;
  width: 10.22%;
  #home-pagination__number {
    bottom: 0;
    top: auto;
  }

  #home-pagination__nav {
    top: 10vh;
  }
  &.home-pagination--dark {
    height: 68.5%;
    height: calc(81.11% - 119px);
    top: auto;
    #home-pagination__number {
      bottom: 10vh;
      top: auto;
      font-size: 2rem;
    }
    #home-pagination__nav {
      top: 10vh;
    }
  }
  &.home-pagination--small {
    top: auto;
    bottom: 10vh;
    #home-pagination__nav {
      top: auto;
      bottom: 2rem;
    }
  }
}
.home-pagination--dark {
  .home-pagination__nav__item {
    &--selected {
      .home-pagination__nav__item__btn::before {
        background-color: #ffffff;
      }
    }
  }
}
// .home-pagination--small {
//   .home-pagination__nav__item {
//     padding-left: 31px;
//   }
// }
.home-pagination__nav__item {
  width: auto;
  font-size: 0.7rem;
  font-weight: 600;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  line-height: 1.5;
  height: 49px;
  letter-spacing: 1px;
  color: $yellow;
  margin-bottom: 1.3rem;
  &:hover {
    color: #ffffff;
  }
  &__timer {
    top: 50%;
    left: -4px;
    margin-top: -12px;
    width: 28px;
    height: 20px;
  }
  &__btn::before {
    top: 50%;
    left: 12px;
    background-color: $yellow;
    transform: translateY(-50%);
    display: none;
  }
  .home-pagination__nav__item__btn::before {
    background-color: $yellow;
  }
  .svgIcon {
    width: 25px;
    height: 49px;
    path {
      fill: #ffffff;
    }
  }
  &--selected {
    .svgIcon path {
      fill: $yellow;
    }
    // color: #ffffff;
    // .home-pagination__nav__item__btn::before {
    //   background-color: #ffffff;
    // }
  }
}
#home-pagination__nav {
  left: 50%;
  top: 41%;
  transform: translateX(-50%);
  margin-left: 0;
}
.home-screen__step-1__lines,
.home-screen__step-2__lines,
.home-screen__step-2__corner,
#home-scroll-cta {
  display: none;
}
.home-screen__step-2 {
  .home-screen__step__content {
    width: 84.58%;
    height: 81.11%;
    align-items: flex-start;
  }
}
.home-screen__step-2__content__inner {
  margin-left: 0;
  padding-top: 0;
  width: 100%;
  // overflow: auto;
  &__subtitle {
    color: $primary-color;
    font-weight: 600;
    font-size: 1.8rem;
  }
  &__body {
    color: #ffffff;
    font-size: 1.1rem;
    letter-spacing: 1px;
  }
  &__title {
    background-color: #000000;
    color: #ffffff;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1;
    padding: 2rem;
    height: 119px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    svg {
      margin-right: 1.5rem;
      height: 3rem;
      path {
        fill: #ffffff;
      }
    }
  }
  &__link {
    color: $primary-color;
    font-size: 0.75rem;
  }
  &__title,
  &__subtitle,
  &__body,
  &__link {
    padding-left: 17%;
    padding-right: 9%;
  }
}

// home Mobile
.homeMobile {
  .banner {
    position: relative;
    img {
      height: 71vh;
      width: 100%;
      object-fit: cover;
    }
    .overlay {
      background-color: rgb(0 0 0 / 50%);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .bannerImage {
    position: relative;
  }
  .bannerContent {
    background-color: $bgGray;
    width: 100%;
    padding: 1.5rem;
    color: #ffffff;
    position: relative;
    z-index: 1;
    svg {
      width: 45%;
      height: 45%;
    }
    span {
      color: $primary-color;
    }
    h2 {
      font-weight: 600;
      line-height: 1.1;
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }
    h3 {
      font-size: 1.1rem;
      color: $primary-color;
      font-weight: 600;
      margin-bottom: 0.7rem;
      line-height: 1.3;
      margin-top: 2rem;
      &:first-child {
        margin-top: 0;
      }
    }
    > div:last-child h3:first-child {
      margin-top: 2rem;
    }
    .pintsDifferentiators {
      margin-bottom: 0;
      padding-left: 1.5rem;
      li {
        margin-bottom: 0.6rem;
        list-style: disc;
        padding-left: 0.5rem;
        line-height: 1.5;
      }
    }
    p {
      font-size: 0.9rem;
      line-height: 1.5;
      letter-spacing: 1px;
    }
  }
  .landingPoints {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 0;
    justify-content: space-between;
    a {
      display: flex;
      width: 47%;
      margin-bottom: 7%;
      flex-direction: column;
      background-color: #000000;
      padding: 1rem;
      border-radius: 5px;
      align-items: center;
      text-align: center;
      justify-content: center;
      span {
        margin-top: 1rem;
        line-height: 1.3;
        color: $yellow;
        font-size: 0.9rem;
      }
    }
    br {
      display: none;
    }
  }
  .bannerText {
    color: #ffffff;
    padding: 3rem 1.5rem;
    position: absolute;
    bottom: 0;
    background-color: rgb(0 0 0 / 50%);
    h2 {
      font-weight: 600;
      line-height: 1.3;
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }
  }
}

// @supports (-webkit-overflow-scrolling: touch) {
//   .homeMobile .banner {
//     background-attachment: scroll;
//   }
// }

// inner banner
section.project-hero {
  height: 85vh;
  .featureValue {
    line-height: 1.6;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 1rem;
    color: #ffffff;
  }
  &::before {
    background: none;
    // background: linear-gradient(
    //   180deg,
    //   rgba(0, 0, 0, 0) 9%,
    //   rgba(0, 57, 69, 0) 50%,
    //   rgba(0, 0, 0, 0.7654412107) 80%,
    //   rgb(0, 0, 0) 100%
    // );
  }
  .text-wrap {
    padding-bottom: 73px;
    max-width: 45vw;
  }
  &.heroPatch .text-wrap {
    position: relative;
    background-color: rgb(17 14 13 / 80%);
    border-top-right-radius: 90px;
    padding-top: 1.5rem;

    &::before {
      content: "";
      display: block;
      background-color: rgb(17 14 13 / 80%);
      width: 100vw;
      bottom: 0;
      top: 0;
      right: 100%;
      position: absolute;
      z-index: 1;
    }
  }
  &.imagedTitle {
    .inner::before {
      width: 47%;
    }
    .project-info .project-title {
      min-height: auto;
      font-size: 1.5rem;
      margin-top: 0.5rem;
      font-weight: 600;
    }
  }
  .project-info {
    bottom: 0;
    .project-title {
      text-shadow: 0 3px 9px $bgBlack;
      font-size: 3rem;
      line-height: 1.2;
      display: flex;
      align-items: center;
    }
  }
  .project-info .project-title,
  .bannerLogo {
    padding-right: 4rem;
  }
  &.heroLight {
    // &::before {
    //   background: linear-gradient(
    //     180deg,
    //     rgb(235 235 235 / 0%) 9%,
    //     rgba(225, 225, 225, 0) 50%,
    //     rgb(247 236 218 / 15%) 79%,
    //     rgb(213 207 200 / 50%) 100%
    //   );
    // }
    .project-info {
      color: $bgGray;
    }
    .project-title {
      font-weight: 600;
      text-shadow: 0 0 25px #fefefe6e;
    }
  }
  margin-bottom: 0;
  .feature {
    padding-right: 3%;
  }
  .feature-title {
    font-size: 1rem;
  }
  .bannerLogo {
    height: 51px;
    margin-bottom: 0.5rem;
  }
  .project-features {
    width: 1335px;
    z-index: 111;
  }
}

.project-hero + .sectionContainer {
  padding-top: 9rem;
}

// cloud computing
.iconBlockVertical {
  display: flex;
  width: 49%;
  background-color: $Gray2;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  box-shadow: 1px 1px 11px rgb(0 0 0 / 30%);
  margin-bottom: 2%;
  position: relative;
  transition: all 0.5s ease;
  img {
    max-width: 7rem;
    margin-left: 16px;
  }
  &:hover {
    background-color: $GrayPanel;
    box-shadow: 0px 0px 11px $bgGray;
    transition: all 0.5s ease;
    .iconContainer svg path,
    .iconContainer svg circle {
      fill: $yellow;
      transition: all 0.5s ease;
    }
    a {
      background-color: $Gray2;
    }
  }
  .iconContent {
    border-left: 2px solid #515151;
    margin: 2rem 1rem 2.7rem;
    padding-left: 1.5rem;
    svg {
      display: none;
    }
    span {
      color: $yellow;
      font-weight: 600;
    }
  }
  h5 {
    text-transform: uppercase;
  }
  .iconContainer {
    min-width: 60px;
    max-width: 60px;
    margin-left: 1rem;
    text-align: center;
    svg {
      height: 60px;
      width: 60px;
      transition: all 0.5s ease;
    }
  }
  a {
    position: absolute;
    background-color: #32353f;
    color: #ffffff;
    font-size: 0.7rem;
    line-height: 1;
    font-weight: 600;
    right: 1rem;
    bottom: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 16px;
    transition: all 0.5s ease;
    &:hover {
      color: $yellow;
      transition: all 0.5s ease;
    }
  }
}
.bgBlack .doCloud {
  .cloudCon {
    background-color: $bgBlack;
  }
}

.doCloud {
  width: 1280px;
  .cloudCon {
    width: 28%;
    padding: 2rem;
    margin-right: 3%;
    margin-bottom: 3rem;
    max-width: 100%;
    background-color: $bgGray;
    height: 373px;
    border-radius: 1.5rem;
    b {
      font-weight: 600;
      color: $yellow;
    }
  }
  h3 {
    font-size: 1.2rem;
    text-transform: uppercase;
    line-height: 1.3;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  .cloudImage {
    width: 74%;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}
.cloudSolution {
  .solutionCon {
    width: 31%;
    text-align: center;
    padding: 3rem 2rem 2rem;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
      background-color: #132036;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 1.1rem;
      padding: 0.7rem 4rem;
      display: inline-block;
      border-radius: 2rem;
      margin-top: auto;
      &:hover {
        background-color: #0d1626;
        color: $yellow;
      }
    }
    &.manageX {
      background-image: linear-gradient(125deg, #051b3f, #0064aa);
      a {
        background-color: #132036;
        &:hover {
          background-color: #0d1626;
        }
      }
    }
    &.galiEye {
      background-image: linear-gradient(125deg, #023b43, #056f7d);
      a {
        background-color: #01272d;
        &:hover {
          background-color: #021d22;
        }
      }
    }
    &.fourSite {
      background-image: linear-gradient(125deg, #2d1108, #982d0c);
      a {
        background-color: #491200;
        &:hover {
          background-color: #340e02;
        }
      }
    }
    img {
      height: 39px;
      margin-bottom: 2rem;
    }
    p {
      margin-bottom: 2rem;
    }
  }
  &.spaceAround {
    .solutionCon {
      width: 45%;
    }
  }
}

.customersQuotes {
  .cloudCon {
    height: 257px;
  }
}

// Education
.paragraphList {
  width: 48%;
  p {
    margin-bottom: 1rem;
  }
}
.imageList {
  width: 48%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  img {
    width: 48%;
  }
}
.educationD4S {
  display: flex;
  background-image: url("./assets/img/education/D4S_PCBanner.jpg");
  background-size: cover;
  align-items: flex-end;
  padding: 1.7rem;
  margin-bottom: 1.5rem;
  .D4SBanner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    img {
      margin-bottom: 1.5rem;
    }
  }
  a {
    white-space: nowrap;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-left: 2rem;
    border: 1px solid #ffffff;
    border-radius: 2rem;
    padding: 0.5rem 1.5rem;
    background-color: #091353;
    &:hover {
      color: $yellow;
      border-color: $yellow;
    }
    svg {
      margin-left: 0.5rem;
      font-size: 1.3rem;
    }
  }
}
.educationParagraph {
  p {
    margin-bottom: 1rem;
  }
}

// resources
.bgBlack .resourcesContainer,
.bgBlack .collageImage {
  background-color: $bgBlack;
}
.sectionContainer .insightsContainer {
  justify-content: flex-start;
  gap: 20px;
}
.resourcesContainer {
  width: 32%;
  background-color: $bgGray;
  border-radius: 1.2rem;
  margin-bottom: 2rem;
  img {
    width: 100%;
    height: 230px;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 1.2rem 1.2rem 0 0;
  }
  .resource {
    padding: 0 1.5rem 1.5rem 1.5rem;
    height: calc(100% - 250px);
    display: flex;

    flex-direction: column;
  }
  .type {
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    color: $primary-color;
    font-weight: 600;
    font-size: 0.8rem;
  }
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    margin-bottom: 2rem;
  }
  h5 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  a {
    color: #ffffff;
    text-align: right;
    display: block;
    font-weight: 600;
    margin-top: auto;
    &:hover {
      color: $yellow;
    }
  }
}

// Collage Image Block
.collageImage {
  background-color: $bgGray;
  display: flex;
  flex-wrap: wrap;
  .collageBig,
  .collage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .collageText {
      width: 25%;
      padding: 1.5rem 2rem;
    }
    img {
      width: 75%;
      object-fit: cover;
    }
  }
  .collage {
    width: 50%;
    flex-direction: row-reverse;
    .collageText {
      width: 50%;
    }
    img {
      width: 50%;
      height: 100%;
    }
  }
}

// defaults
#ajax-loading__txt,
#main-loading__progress__txt {
  color: #ffffff;
  font-size: 12px;
}
.page-transition {
  background-color: $bgGray;
  padding: 9rem 0;
  // position: relative;
  // z-index: 1;
  color: #ffffff;
}

.slider {
  .sliderKeywords {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2.5rem;
    li {
      background-color: $bgGray;
      padding: 0.3rem 1rem;
      margin-right: 1rem;
      border-radius: 20px;
      font-size: 0.9rem;
      line-height: 1.3;
      margin-bottom: 0.8rem;
    }
  }
}

// Insight
.insight-titleTab {
  display: flex;
  gap: 20px;
  margin-bottom: 2.5rem;
  p {
    padding: 7px 25px;
    background: $Gray2;
    font-weight: 600;
    color: #fff;
    border: 1px solid $GrayPanel;
    font-size: 1rem;
    border-radius: 9px;
    cursor: pointer;
    &.active {
      color: $yellow;
      background-color: $GrayPanel;
    }
  }
}
.insightSearch {
  width: 30%;
  margin-bottom: 2.5rem;
  .MuiTextField-root {
    width: 100%;
  }
  .MuiOutlinedInput-root {
    border-radius: 9px;
  }
  .MuiInputBase-input {
    padding: 13px 14px;
  }
  .MuiFormLabel-root {
    line-height: 1.1;
    // transform: translate(14px, 13px) scale(1);
  }
  .MuiSvgIcon-root {
    fill: #ffffff;
    cursor: pointer;
    &:hover {
      fill: $primary-color;
    }
  }
}
section.project-hero.insightBanner {
  .project-info {
    p {
      font-size: 1.3rem;
      color: $primary-color;
      font-weight: 600;
    }
  }
  .featureValue {
    color: $yellow;
    font-weight: 600;
    font-size: 1.3rem;
  }
}

.imagemodalContainer-root {
  height: 100vh;
  width: 100vw;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .imgHeader {
    position: absolute;
    width: 100%;
    top: 0;
    display: flex;
    svg {
      color: #fff !important;
    }
    .iconbtn {
      margin-left: auto;
      margin-right: 10px;
    }
  }
  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.bss-wrapper {
  flex-direction: column;
}

.bss-mainContainer {
  margin-bottom: 40px;
  background-color: $Gray2;
  border: 2px solid white;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 2rem;
  background-size: auto 65px;
  background-repeat: no-repeat;
  background-position: top 1rem right 1rem;
  border-top-right-radius: 2rem;
  .bss-maintitle {
    font-size: 2.1rem;
    color: $yellow;
    font-weight: 600;
  }
  .bss-valueWrapper {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    justify-content: space-between;
  }
  .bss-valueContainer {
    display: flex;
    flex-direction: column;
    width: 33%;
  }
  .bss-value {
    font-size: 3.5rem;
    font-family: "AbadiBold";
    line-height: normal;
    font-weight: 700;
  }
}

.whoweare-hoverContainer {
  width: 32.75% !important;
}
// about us
.aboutCampaign {
  background-color: $GrayPanel;
  display: flex;
  align-items: center;
  color: #fff;
  padding: 0.7rem;
  img {
    height: 80px;
    margin-right: 1rem;
  }
  p {
    font-size: 0.8rem;
    line-height: 1.5;
  }
}
.aboutPdfDownload {
  text-align: center;
  font-weight: 600;
  margin-top: 2rem;
  width: 100%;
}
.aboutImages {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  img {
    width: 47%;
  }
  &.small {
    justify-content: space-around;
    img {
      width: 23%;
      object-fit: contain;
    }
    .lakeLogo {
      width: 195px;
    }
  }
}
.whoWeAreSlide {
  .hoverSlideUp {
    width: 49%;
    height: 350px;
    margin-bottom: 2rem;
    &:first-child {
      width: 100%;
    }
  }
}
.bgBlack .logosImageContent img {
  background-color: $bgGray;
}
.logosImageContent {
  margin-top: 50px;
  margin-bottom: 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  .container {
    justify-content: space-around;
  }
  img {
    width: 150px;
    object-fit: contain;
    position: relative;
    padding: 0 15px;
    position: relative;
    background-color: $bgBlack;
  }
  &:after,
  &:before {
    content: "";
    width: 109px;
  }
  .licLine {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: $primary-color;
    top: 50%;
    margin-top: -1px;
    z-index: 0;
  }
}
.aboutus {
  height: 25rem;
  background: #000;
  border-radius: 0;
  justify-content: flex-end;
  align-items: center;
  background-size: cover;
  h3 {
    font-size: 2rem;
    margin-top: 60px;
  }
}
.aboutus:hover {
  h3 {
    font-size: 2rem !important;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.solution-stories-container {
  margin-top: 4rem;
}
.solution-link {
  color: $yellow;
  font-weight: 600;
  margin-top: 1rem;
  // display: block; effecting vertical carousel
  // text-align: right;
}

.solution-galieye-what-if-container {
  width: 33%;
  margin-bottom: 0.5%;
}
section.text-aside-image aside.textarea .copy ul li::marker {
  color: #fff;
}

// ManageX
.solution-managex-blog-card-main-wrapper {
  display: flex;
  justify-content: space-between;
  .solution-managex-blog-card-main-contatier {
    width: 32%;
    padding: 1.5rem;
    box-shadow: rgba(27, 27, 27, 0.356) 0px 7px 29px 0px;
    background: $Gray2;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    img {
      height: 70px;
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 1rem;
    }
    a {
      color: $yellow;
      text-align: right;
      display: block;
      margin-top: auto;
      margin-left: auto;
      &:hover {
        color: $primary-color;
      }
    }
  }
}

// Digital4Security
.d4uLogos {
  .content ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0;
    margin-top: 3rem;
    li {
      list-style: none;
      width: 41%;
    }
  }
}

// Footer
footer.primary {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.7) 59%,
      rgba(0, 0, 0, 0.9) 100%
    ),
    url("./assets/img/footer/footermain-bg.png");
  background-repeat: no-repeat;
  padding-top: 6rem;
  background-color: $bgBlack;
  .footer-cta {
    align-items: center;
    margin-bottom: 0;
    padding-bottom: 6rem;
    .title {
      max-width: 571px;
    }
  }
  .footer-main {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
  .col {
    flex: unset;
    width: 33%;
    margin-bottom: 3rem;
    &:last-child {
      width: 100%;
    }
  }
  .footer-col-title {
    margin-bottom: 1.3rem;
    font-size: 0.7rem;
    letter-spacing: 3px;
  }
  .footer-bottom {
    padding-bottom: 0;
  }
}
ul.social-links {
  a {
    justify-content: flex-start;
    width: 57px;
  }
  svg {
    max-width: 25px;
  }
}
.footer-ms-img-container {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 3rem;
  align-items: flex-start;
  gap: 5rem;
  img {
    width: 130px;
  }
}
.footer-tab-class {
  background-color: $bgGray;
}

.footer-copyright-container {
  display: flex;
}

.footerbody {
  position: relative;
  z-index: 10;
}
.footer-col-name p {
  margin-bottom: 34px;
  font-weight: 600;
  font-size: 11px;
  cursor: pointer;
  font-size: 0.6875rem;
  text-transform: uppercase;
  &:hover {
    color: $yellow;
  }
}
.scrollUp {
  display: none;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 99;
  transition: opacity 0.5s, visibility 0.5s;
  cursor: pointer;
  svg {
    width: 50px;
    height: 50px;
    opacity: 0.8;
    transition: opacity 0.5s;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
}

.logoCarouselSlick {
  background-color: $Gray2;
  img {
    height: 25px;
    margin: auto;
  }
}

.blog-managex-body {
  margin-top: 1.5rem;
  text-align: center;
  p {
    margin-top: 1rem;
  }
  a {
    font-weight: 600;
    color: $yellow;
    &:hover {
      color: $primary-color;
    }
  }
}

.solution-managex-blog-card-title-container {
  width: fit-content;
  display: flex;

  color: white;
  img {
    width: fit-content;
    object-fit: contain;
    max-width: 100px;
  }
}

.solution-managex-blog-card-image {
  max-width: 15rem;
  margin-bottom: 20px;
}

.ImageContent-body-title {
  margin-bottom: 0 !important;
  color: #fff;
  font-weight: bold;
}

.ImageContent-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ImageContent-main-title {
  font-size: 5rem;
}

.ImageContent-body-image {
  width: 35rem !important;
  height: 35rem !important;
  object-fit: cover;
}

.imagecontentcstm {
  justify-content: center;
  gap: 4rem;
  align-items: flex-start;
}

.ImageContent-body-container {
  color: #fff;
  margin-top: 20px;
}

.ImageContent-main-title {
  font-size: 4rem;
  font-weight: 600;
  color: $primary-color;
  line-height: normal;
  margin-bottom: 20px;
}

.foursite-ethicalai-body {
  span {
    color: #ffffff;
    font-weight: 600;
  }
}

.ImageContent-section-container {
  display: flex;
  gap: 30px;
  align-items: flex-start;
  img {
    object-fit: contain;
    height: 100% !important;
  }
}

.foursite-ImageContent-wrapper {
  .ImageContent-body-container {
    min-width: 60%;
    margin-top: 0;
  }
  .content {
    width: 65%;
  }
  .ImageContent-body {
    font-size: 16px;
    margin: 0;
  }
  .ImageContent-section-container {
    margin-bottom: 50px;
  }
}
.usecase-container {
  a {
    color: $yellow;
    font-weight: 600;
  }
}

.wrapper-flip {
  align-items: flex-start;
  justify-content: space-between !important;
}

.logo-banner-container {
  .foursitecustomLogoImage {
    max-width: 13rem !important;
    min-width: 13rem !important;
    max-height: 4rem !important;
  }
  .project-title-logo {
    max-width: 20rem;
    min-width: 20rem;
  }
  .logobanner-text-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;

    h3 {
      font-size: 40px;
    }
  }
  .project-features {
    width: fit-content !important;
  }
}

.logobanner-text-wrap {
  h3 {
    font-size: 50px;
    line-height: normal;
  }
}
.logo-banner-title {
  bottom: 200px !important  ;
}

.stories-tile {
  .collageImage .collageBig img {
    width: 100%;
  }
}

.solution-mx-quotesCustomers {
  .doCloud .cloudCon {
    height: 300px;
    background: $Gray2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    h3 {
      display: none;
    }
    p {
      font-style: italic;
      b {
        font-style: italic;
      }
    }
  }
}

.solution-quotesCustomers {
  .doCloud .cloudCon {
    height: 300px;
    background: #23252d;
    display: flex;
    flex-direction: column;
    p {
      font-style: italic;
    }
  }
}

.main-container {
  background-color: #000 !important;
}

.CrousalListItems {
  list-style-type: disc;
  margin-left: 2rem;
}

.solution-carosul {
  .content-inner {
    display: flex;
    flex-direction: column;
  }
}

.CrousalListItems::marker {
  color: white;
}

.solution-gelieye {
  padding: 0 0 0 5%;
}

.ctsomvalue-container {
  padding: 0 5% 0 0 !important;
}

.featureParagraphHeading {
  font-size: 28px !important;
  color: $primary-color !important;
}

.insightTitleDetails {
  font-size: 26px;
  font-weight: 600;
  color: #ebebeb;
}

.insight-section-paragraph {
  color: #ebebeb;
  padding-bottom: 2rem;
  a {
    color: #ebebeb;
  }
}
.insight-section-source {
  color: #ebebeb;
  font-size: 14px;
  a {
    color: #ebebeb;
  }
  a:hover {
    border-bottom: 1px solid #ebebeb;
  }
}
.pb-0 {
  padding-bottom: 0;
}

.pt-0 {
  padding-top: 0;
}

.insight-section-title {
  font-size: 1.3rem;
  line-height: normal;
  margin-bottom: 20px;
  font-weight: 600;
}

.insight-section-list {
  margin-left: 20px;
  margin-bottom: 2rem;
}

.insight-section-list li {
  list-style: disc;
  .insight-section-paragraph {
    padding-bottom: 10px;
  }
}

.insight-image-contaner {
  padding-bottom: 2rem;
}

.insight-section-container {
  display: flex;
  flex-direction: column !important;
}

.svgicon {
  stroke: white;
  width: 25px;
  fill: white;
  height: 50px;
}

.insight-navigation {
  display: flex;
  align-items: center;
  a {
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25rem;
    font-weight: 600;
  }
  a:hover {
    text-decoration: underline;
    color: $yellow;
  }
}

.flip {
  flex-direction: row-reverse;

  .flipped-text-container {
    padding: 0 5% 0 0 !important;
  }
}

.wrap-text-around-image {
  .copy {
    list-style: none !important;
    span {
      font-weight: 500 !important;
    }
  }
}

.agilePipelines {
  .left {
    float: left !important;
    margin: 0px 40px 40px 0 !important;
    height: 34rem !important;
  }
}

.wrapvaluePropsContainer {
  .wrap-text-around-image {
    margin-top: 3rem;
  }
  .container {
    display: block !important;
    .left {
      float: right;
      height: 600px;
      margin: 0 40px 40px 40px;
      width: 50%;

      // margin: 0 33px;
      .image {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.wrap-text-around-image .left {
  float: left;
  width: 40%; /* Adjust this percentage as needed */
  margin-right: 20px; /* Space between the image and text */
}

.wrap-text-around-image .right {
  overflow: hidden; /* Clear floats */
}

.headContainer {
  flex-direction: column;
}

.pageInsightbContainer {
  display: flex;
  gap: 60px;
}

.insight-image-contaner2 {
  width: 50%;
  padding-bottom: 0;
}

.pageInsightbContainer-para-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.download-btn {
  background: #474747;
  width: 50%;
  margin-top: auto;
  color: #ebebeb;
  text-align: center;
  padding: 10px 30px;
  border-radius: 50px;
}

.contact-formsubmit {
  width: 10rem;
  background-color: #000;
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
}

.contact-form-fields-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-us-model-backdrop {
  background-color: rgba(0, 0, 0, 0.9) !important;
  &::-webkit-scrollbar {
    display: none;
  }
}

.custom-textfield {
  .MuiOutlinedInput-notchedOutline {
    border: none;
    border-bottom: 2px solid;
    border-bottom-color: #626262 !important;
    border-radius: 0;
  }

  .MuiOutlinedInput-root {
    .MuiOutlinedInput-notchedOutline {
      border-color: white; // Default border color
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: white; // Border color when hovered
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: white; // Border color when focused
    }
  }
  .MuiInputLabel-root {
    color: white; // Default label color
  }
  .MuiInputLabel-root.Mui-focused {
    padding-bottom: 20px;
    color: white; // Label color when focused
  }

  .MuiInputBase-input {
    color: white; // Text color
  }
}

.titleCustomParagraph {
  display: flex;
  align-items: center;
  gap: 20px;
  span {
    font-size: 20px;
    font-weight: 500;
  }
  .titleParagraph {
    color: #f05c24;
    font-size: 28px;
    font-weight: 600;
  }
}

.flipoxwithimage {
  flex-direction: column;
  background-color: #000;
  align-items: center !important;
  gap: 20px;
}

.flipoxwithimage::before {
  display: none !important;
}

.whoweare-custom-container {
  .textCenteredBlock {
    h4 {
      text-align: start;
      color: #fff;
      font-size: 28px;
      font-weight: 600;
    }
    p {
      text-align: start;
    }
  }
}
.locationMapImage {
  width: 100%;
}
.locations {
  width: 32%;
  background-color: $GrayPanel;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1.5rem;
  position: relative;
  .mapTitle {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    img {
      height: 33px;
      margin-right: 1rem;
    }
    h5 {
      margin-bottom: 0;
    }
  }
  svg {
    position: absolute;
    right: 0.3rem;
    bottom: 0.3rem;
    cursor: pointer;
    font-size: 1.1rem;
  }
}

.whatWeDoContainer {
  justify-content: center !important;
  gap: 40px !important;
}

.HoverShowContent-image {
  max-width: 5rem;
}

.contant-class {
  flex: none !important;
  a {
    color: #f1b71c99;
  }
  a:hover {
    color: #f1b71c;
    text-decoration: underline;
  }
}

.accept-btn {
  color: #fff !important;
  font-weight: 600;
  background: #ffffff40 !important;
  border-radius: 50px !important;
  padding: 0 20px !important;
  font-size: 13px;
  letter-spacing: 1px;
  &:hover {
    color: $yellow !important;
    background: #ffffff50 !important;
  }
}

.accordianCarrier.MuiAccordion-root {
  width: 100%;
  background-color: $GrayPanel;
  margin-bottom: 0.5rem;
  &.titledAccordion {
    background-color: transparent;
    margin-bottom: 0;
    .MuiAccordionSummary-root {
      min-height: unset;
    }
    .jd-summary-container-icon {
      opacity: 0;
    }
    .jd-summary-job,
    .jd-summary-text {
      color: #ffffff;
      font-weight: 600;
      font-size: 1rem;
    }
  }
  &.Mui-expanded {
    margin: 0 0 0.5rem 0;
  }
  .jd-summary-container-icon {
    font-size: 2rem;
    color: #868686;
  }
  .jd-summary-container {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: 20px;
  }
  .jd-summary-job {
    color: $yellow;
    font-weight: 600;
    min-width: 40%;
    width: 40%;
  }
  .jd-summary-text {
    color: #ffffff;
    &:nth-child(2) {
      min-width: 30%;
    }
  }
  .accept-btn {
    margin-left: auto;
  }
}

.jd-summary-button {
  color: #fff;
  font-weight: bold;
  background: $Gray2;
  border-radius: 50px;
  padding: 0 20px;
  font-size: 13px;
}

.jd-summary-section {
  margin-top: 50px;
}

.jd-summary-sectionTitle {
  font-size: 18px;
  color: #fff;
  margin-bottom: 5px;
  line-height: normal;
  font-weight: 700;
}

.jd-summary-details {
  font-size: 15px;
  line-height: normal;
  color: #fff;
}

.jd-details-body {
  padding: 8px 16px 50px 16px !important;
}

.jd-summary-list li {
  list-style: disc;
  margin-left: 20px;
}

.jd-summary-list li::marker {
  color: white;
}

.copyright,
.terms-privacy-container {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 300;
  color: #fcfcfc;
}
.copyright {
  padding-bottom: 0.5rem;
}

.terms-privacy-container {
  margin-left: auto;
  a {
    color: $yellow !important;
    &:hover {
      text-decoration: underline;
    }
  }
}

.pt50 {
  padding-top: 50px;
}

.pb30 {
  padding-bottom: 30px;
}

.pt30 {
  padding-top: 30px;
}

.CookieConsent {
  justify-content: center !important;
  font-size: 1rem;
  z-index: 1001 !important;
  .content-class {
    flex: none !important;
    a {
      color: $yellow;
      font-weight: 600;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}

// .location-wrapper {
//   max-width: 25%;
//   min-width: 25%;
// }

.location-container {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
}

.about-usparagraph {
  a {
    color: $yellow;
  }
}

.mb-10 {
  margin-bottom: 10px !important;
}

#full-screen-menu {
  display: none;
  color: $yellow;
  .sectors-items {
    overflow: hidden;
    .sector-item button {
      color: #dbdcdd;
    }
  }
  .contact-info {
    .phone,
    a {
      color: #ffffff;
    }
  }
}

// leaders
.leadershipTeam {
  // &.container {
  //   justify-content: space-around;
  // }
  .leaders {
    width: 23%;
    margin-right: 2rem;
    margin-bottom: 3rem;
    &:first-child,
    &:nth-child(2) {
      width: 29%;
    }
    img {
      margin-bottom: 1.5rem;
    }
    h5 {
      margin-bottom: 0.3rem;
      font-size: 1.7rem;
      color: $primary-color;
      letter-spacing: 2px;
    }
    p {
      text-transform: uppercase;
      margin-bottom: 0.3rem;
      font-weight: 600;
    }
    a {
      color: #0077b5;
      &:hover {
        color: #ffffff;
      }
      svg {
        font-size: 2.1rem;
      }
    }
  }
}

// responsive below

@media (max-width: 1366px) {
  #home-landing__content #home-landing__content__text {
    h1 {
      padding-right: 0;
      font-size: 2.5rem;
    }
    .mainPara {
      padding-right: 0;
      font-size: 1.2rem;
    }
  }
  .home-screen__step__content.home-screen__step-1__content {
    > h1 {
      font-size: 2.5rem;
      padding-right: 0;
    }
    > h2 {
      padding-right: 0;
      font-size: 1.2rem;
    }
  }
  #home-pagination {
    width: 9.22%;
  }
}

@media (max-width: 1280px) {
  h1 {
    font-size: 2.5rem;
  }

  .featureParagraphHeading {
    font-size: 24px !important;
  }

  .project-title {
    width: 100%;
    // padding: 20rem 0 0 0 !important;
    a {
      word-break: break-all;
    }
  }

  .pageInsightbContainer {
    flex-direction: column-reverse;
    margin-top: 12rem;
  }

  // .resourcesContainer .type {
  //   height: 5rem;
  // }

  .cloudCon p {
    line-height: normal;
  }
  .logo-banner-container .project-title-logo {
    max-width: 12rem !important;
    min-width: 12rem !important;
  }
  .insight-navigation a {
    max-width: 10rem !important;
  }

  .pageInsightbContainer-para-container {
    width: 100% !important;
  }

  .insight-image-contaner2 {
    width: 100% !important;
  }
  .download-btn {
    width: 100% !important;
  }
  .logobanner-text-wrap h3 {
    font-size: 30px !important;
  }
  .carouselTitleNav .flickity-slider > div {
    font-size: 1.1rem;
  }
  header.primary .menu-header-menu-container {
    display: block;
    right: 0;
    transform: none;
    left: auto;
  }
  #menu-header-menu {
    height: 64px;
    line-height: 64px;
    li {
      padding: 0 6px;
      .sub-menu {
        right: 0;
        padding-right: 0;
        &:before {
          height: 0;
        }
      }
    }
  }
  .hoverContent,
  .hoverContent.threeColumn {
    h5 {
      font-size: 1.5rem;
    }
    h3 {
      font-size: 3.5rem;
    }
    &.aboutus h3 {
      font-size: 2rem;
    }
  }
  // Slick
  .slickContainer .playPause {
    right: 1rem;
    bottom: 0;
  }
} // 1280px

@media (max-width: 1195px) {
  .home-screen__step__content.home-screen__step-1__content > h2 {
    font-size: 1.7rem;
    min-height: 247px;
  }
  .home-pagination__nav__item .svgIcon {
    width: 23px;
  }
  #menu-header-menu li {
    font-size: 10px;
  }
  #home-landing__content #home-landing__content__text ul p {
    font-size: 1rem;
  }
  // valueprops
  section.project-hero .project-features {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  // collage Image -Edge Computing
  .collageImage {
    flex-direction: column;
    .collageBig,
    .collage {
      flex-direction: column;
      width: 100%;
      .collageText {
        width: 100%;
      }
      img {
        width: 100%;
      }
    }
  }
  // leadership
  .leadershipTeam .leaders {
    width: 27%;
    img {
      margin-bottom: 1rem;
    }
    h5 {
      font-size: 1.3rem;
    }
    p {
      font-size: 1rem;
    }
  }
  // Finance
  .whoWeAreSlide .hoverSlideUp {
    width: 100%;
  }
  // content block
  .textButtonBlock {
    width: 48%;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  .project-hero + .sectionContainer {
    padding-top: 7.5rem;
  }
  .iconSmallBlock {
    flex-wrap: wrap;
  }
} // 1195px

@media (max-width: 1023px) {
  .valuePropsContainer section.bgImageBlock,
  .sectionContainer,
  .valuePropsContainer + .valuePropsContainer section.bgImageBlock {
    padding: 3rem 0;
  }
  .valuePropsContainer .imageContained .image {
    position: relative;
  }
  .container,
  .container--small {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .footer-cta {
    align-items: flex-start !important;
    margin-bottom: 30px !important ;
  }
  .wwd-customClassContainer {
    flex-direction: column;
    gap: 20px;
  }
  .bss-value {
    font-size: 32px !important;
  }
  .bss-mainContainer {
    height: 100% !important;
  }
  .bss-valueWrapper {
    flex-direction: column;
  }
  .bss-mainContainer .bss-valueContainer {
    width: 100%;
  }
  .bss-title {
    font-size: 14px !important;
  }
  .whoweare-hoverContainer {
    width: 100% !important;
  }
  .ltw-container {
    flex-direction: column;
    .ltw-wrapper {
      width: 100%;
      margin-bottom: 1.5rem;
    }
  }
  .jd-summary-button {
    width: fit-content;
    margin-top: 10px;
  }
  .accordianCarrier.MuiAccordion-root {
    &.titledAccordion {
      display: none;
    }
    .jd-summary-container {
      flex-direction: column;
      align-items: flex-start;
    }
    .jd-summary-job {
      min-width: unset;
      width: 100%;
    }
    .accept-btn {
      margin-right: auto;
    }
  }
  .modalContainer {
    width: 97vw;
  }

  .contact-form {
    margin-bottom: 20px;
  }

  .flex-reverse {
    flex-direction: column !important;
    gap: 20px;
  }

  .quotes-container {
    padding: 0 !important;
  }

  .quote {
    min-height: 8rem;
    padding: 29px 22px 0 22px;
    font-size: 12px !important;
    line-height: normal;
  }

  .disabletile {
    background-color: #000 !important;
  }

  .solution-gelieye {
    padding-left: 0 !important;
    padding-top: 3.5rem !important;
  }

  .container,
  .container--small {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  // ManageX
  .UniversitySlick .imageText {
    flex-direction: column-reverse;
    .text,
    img {
      width: 100%;
    }
  }
  // header
  header.primary {
    height: 83px;
    #main-logo {
      height: 78px;
    }
    .menu-header-menu-container {
      display: none;
    }
  }
  .hamburger-menu {
    display: block;
    position: absolute;
    right: 15px;
    top: 0;
  }
  #full-screen-menu {
    .primary-menu li.menu-item > span {
      font-size: 2.25rem;
      letter-spacing: 0.1rem;
      line-height: 2.75rem;
    }
    .sectors-items .sector-item {
      font-size: 1.5rem;
    }
    display: block;
    .container {
      padding-top: 4rem;
      padding-bottom: 3rem;
    }
  }
  body.is-fixed {
    overflow: hidden;
    position: fixed;
    max-height: 100%;
    width: 100%;
  }
  #home-landing__scroll {
    display: none;
  }
  #home-landing__content {
    bottom: 0;
    #home-landing__content__text {
      ul {
        justify-content: space-between;
        li {
          margin-right: 1rem;
          &:focus-visible {
            outline: -webkit-focus-ring-color auto 1px;
          }
        }
        p {
          font-size: 0.9rem;
        }
      }
      h1 {
        font-size: 2rem;
      }
      .mainPara {
        font-size: 1.1rem;
      }
    }
  }
  .home-pagination--small #home-pagination__number {
    bottom: 265px;
  }
  #home-pagination {
    width: 11.43%;
    background-color: transparent;
  }
  #home-pagination__nav {
    top: 23%;
  }
  .home-pagination__nav__item {
    height: 35px;
    .svgIcon {
      width: 25px;
      height: 25px;
    }
  }
  #home-screen-1,
  #home-screen-2,
  #home-screen-3,
  #home-screen-4 {
    .home-screen__step-2__img {
      position: absolute;
      top: 0;
      bottom: 0;
      display: block;
      background-position: center center;
    }
  }
  .home-pagination__nav__item__timer {
    margin-top: -15px;
  }
  .home-pagination--dark .home-pagination__nav__item {
    margin-bottom: 2.1rem;
  }
  footer.primary {
    position: relative;
    top: -1px;
    .col {
      width: 100%;
    }
    .footer-cta {
      padding-bottom: 3rem;
    }
  }
  .location-container {
    gap: 10px 25px;
    margin-bottom: 35px;
  }
  .footer-col-name {
    margin-bottom: 0;
  }
  .scrollUp {
    display: block;
  }

  .home-screen__step__content.home-screen__step-1__content {
    > h1 {
      font-size: 2rem;
    }
    > h2 {
      font-size: 1.2rem;
      line-height: 1.5;
    }
  }

  // spacing
  // .sectionContainer {
  //   padding: 6rem 0;
  // }
  .mainTitle {
    margin-bottom: 1.8rem;
  }
  // banner
  section.project-hero {
    height: unset;
    .inner {
      height: 75vh;
    }
    .text-wrap {
      padding-top: 1rem;
      padding-bottom: 0.5rem;
    }
    &.heroPatch {
      .inner::before {
        height: 165px;
      }
      .text-wrap {
        border-top-right-radius: 31px;
        max-width: unset;
      }
    }
    .project-info {
      .project-title {
        font-size: 1.3rem;
        line-height: 1.5;
        font-weight: 600;
        padding-right: 2rem;
        padding-left: 0;
      }
    }
    .project-features {
      padding: 2rem 1.5rem;
      position: relative;
      top: unset;
      bottom: unset;
    }
  }
  .image.paroller {
    height: 100%;
  }
  // content Blocks
  .iconSmallBlock ul {
    flex-wrap: wrap;
    gap: 1.5rem;
    li {
      width: 20%;
    }
  }
  .hoverContent,
  .hoverContent.threeColumn {
    width: 48%;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    svg {
      display: block;
      position: absolute;
      left: 1rem;
      bottom: 1rem;
    }
  }
  .ImageContent {
    .imageBackground {
      width: 49%;
    }
  }
  .doCloud .cloudCon {
    width: 30%;
    height: 397px;
  }
  .hoverSlideUp {
    width: 49.75%;
  }
  .service-box.three {
    width: 100%;
  }
  .project-hero + .sectionContainer {
    padding-top: 7rem;
  }
  .ImageContent.ModalImageContent {
    flex-direction: column;
    .imageBackground {
      width: 100%;
      margin-bottom: 1.5rem;
      img {
        display: block;
      }
    }
    .content {
      width: 100%;
    }
  }
  .wrapvaluePropsContainer .container .left {
    float: none;
    width: 100%;
    margin: 0 0 2rem;
  }
  // cloud computing
  .sectionContainer {
    .iconBlockVertical {
      width: 100%;
      margin-bottom: 1.5rem;
    }
  }
  .service-box {
    width: 32%;
  }
  .cloudSolution .solutionCon a {
    padding: 0.4rem 1.3rem;
    font-size: 0.8rem;
  }
  // carousel
  .carouselTitleNav {
    display: none;
  }
  .textCarouselSlick {
    padding: 0 53px;
    padding-bottom: 3rem;
    margin-bottom: 0;
  }
  .slick-slider {
    .slick-next,
    .slick-prev {
      width: 37px;
      height: 37px;
      &::before {
        background-size: 14px 10px;
      }
    }
  }
  .slick-dots {
    position: static;
    li {
      width: 10px;
      height: 10px;
      margin: 0 6px;
      button {
        width: 10px;
        height: 10px;
        padding: 0;
        &:before {
          width: 10px;
          height: 10px;
          font-size: 0;
          background-color: #ffffff;
          border-radius: 50%;
        }
      }
      &.slick-active button:before {
        opacity: 1;
      }
    }
  }
  .hideArrows {
    .controls {
      display: block;
    }
  }
  section.services-intro {
    .col.left,
    .col {
      width: 100%;
    }
    .left {
      padding-top: 2rem;
    }
    .right {
      padding-left: 0;
      padding-top: 0;
    }
    .container {
      flex-direction: column-reverse;
    }
    &.view-detect.viewed .media {
      height: unset;
    }
    &.imageContained .media .image {
      position: relative;
      width: 65vw;
      display: block;
      margin: auto;
    }
  }
  // insight
  .insight-titleTab {
    flex-wrap: wrap;
    gap: 17px;
    p {
      padding: 3px 17px;
      font-size: 0.9rem;
    }
  }
  .insightSearch {
    width: 100%;
  }
  // resources
  .resourcesContainer {
    width: 48%;
    margin-bottom: 1.5rem;
  }
} // 1023px

@media (max-width: 959px) {
  section.services-intro {
    margin-top: unset;
  }
  // content blocks
  .ImageContent {
    flex-direction: column;
    &.rowReverse {
      flex-direction: column;
    }
    .imageBackground {
      width: 100%;
      img {
        display: block;
      }
    }
    .content {
      margin-top: 2rem;
      width: 100%;
      padding: 0;
    }
  }
  .solution-managex-blog-card-main-wrapper {
    flex-direction: column;
    .solution-managex-blog-card-main-contatier {
      width: 100%;
      margin-bottom: 1.5rem;
    }
  }
  // about us
  .locations {
    width: 100%;
  }
}

@media (max-width: 767px) {
  p,
  body {
    font-size: 0.9rem;
    line-height: 1.8;
  }
  h5,
  .resourcesContainer h5 {
    font-size: 1rem;
    margin-bottom: 0.8rem;
  }
  .mainTitle .title,
  hgroup.view-detect.viewed .section-subtitle,
  .textCenteredBlock h4,
  .ImageContent h5 {
    font-size: 1.8rem;
  }
  .btn {
    padding: 0.5rem 1.2rem;
  }
  .button-bordered-white {
    font-size: 0.9rem;
  }
  // Finance
  .whoWeAreSlide .hoverSlideUp {
    height: 400px;
  }
  // Careers
  .modalContainer .field-container {
    width: 100%;
    label {
      font-size: 0.9rem;
    }
    input {
      font-size: 0.8rem;
    }
  }
  // cloud computing
  .sectionContainer .iconBlockVertical {
    .iconContainer {
      display: none;
    }
    .iconContent {
      border: none;
      margin: 0;
      padding: 1.5rem;
      h5 {
        display: flex;
        align-items: center;
        margin-bottom: 0.7rem;
      }
      img {
        display: block;
        height: 55px;
        margin-right: 1rem;
      }
    }
  }
  .slider .sliderKeywords li {
    font-size: 0.7rem;
  }
  // leadership
  .leadershipTeam .leaders {
    &:first-child,
    &:nth-child(2) {
      width: 100%;
    }
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.5rem;
    img {
      margin-bottom: 0.5rem;
    }
    h5 {
      font-size: 1.1rem;
      margin-bottom: 0;
    }
    p {
      font-size: 0.9rem;
    }
  }
  // content blocks
  .agilePipelines .left {
    height: auto !important;
  }
  .wrapvaluePropsContainer .container .left {
    height: auto;
  }
  .wrapvaluePropsContainer .wrap-text-around-image {
    margin-top: 0;
  }
  .educationD4S {
    flex-direction: column;
    a {
      justify-content: center;
      margin: 1.5rem auto 0;
    }
  }
  .collageImage .collageBig .collageText,
  .collageImage .collage .collageText {
    padding: 1.5rem;
  }
  .iconSmallBlock ul {
    justify-content: space-around;
    li {
      width: 45%;
    }
  }
  .service-box {
    width: 48%;
    margin-bottom: 4%;
    .service-icon,
    .service-content {
      height: 260px;
      p {
        font-size: 0.9rem;
      }
      img {
        height: 45px;
        margin-bottom: 0.5rem;
      }
    }
    .service-icon {
      align-items: center;
      svg {
        height: 51px;
        // display: block;
      }
    }
    &.bgImage {
      .service-icon {
        align-items: flex-end;
      }
    }
  }
  .collageFlipBox .service-icon {
    h3 {
      font-size: 1.1rem;
      margin-top: 0;
    }
    h6 svg {
      height: 51px;
    }
  }
  .doCloud .cloudCon {
    width: 79%;
    margin-right: 7%;
  }
  .accordionPanel .content {
    flex-wrap: wrap;
    > div {
      margin-bottom: 1.5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .titleParagraphBlock,
  .textBoxColored,
  .paragraphList,
  .imageList,
  .cloudSolution.spaceAround .solutionCon,
  .cloudSolution .solutionCon,
  .accordionPanel .content > div {
    width: 100%;
  }
  .partnersBlock .partnersContainer {
    > div:first-child,
    > div.multipleBlocks,
    .multipleBlocks > div:first-child,
    > div.multipleBlocks > div {
      width: 100%;
    }
  }
  .resourcesContainer {
    a {
      margin-top: unset;
    }
    .resource {
      height: auto;
    }
  }
  .textButtonBlock {
    width: 100%;
    // > a{
    //   display: inline-flex;
    //   span{
    //     margin-left: 2rem;
    //   }
    // }
  }
  .TitleImageContentBlock .textBlocks {
    .image,
    .content {
      width: 100%;
    }
    .content {
      margin-top: 2rem;
    }
  }
  .collageFlipBox {
    img {
      display: none;
    }
    .service-box,
    .multipleItems {
      width: 100%;
    }
  }
  .hoverSlideUp {
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .textCarouselSlick {
    padding: 0 0 1.5rem;
    .slick-track {
      h3 {
        font-size: 0.9rem;
        line-height: 1.7;
        margin-bottom: 1rem;
      }
      .slick-slide {
        display: flex;
        align-items: center;
      }
      .slickCon {
        padding: 1rem 1.5rem;
      }
    }
  }
  .grayBox.three {
    width: 100%;
  }
  .project-hero + .sectionContainer {
    padding-top: 3rem;
  }
  section.services-intro {
    .imageDetails {
      position: relative;
      bottom: 0;
    }
    .section-subtitle {
      br {
        display: none;
      }
    }
  }
  .solution-managex-blog-card-main-wrapper
    .solution-managex-blog-card-main-contatier
    img {
    height: 43px;
  }
  .slickTitleNav {
    display: none;
  }
  .slickTitleCon {
    .slick-slide div[tabindex] {
      flex-direction: column;
      img {
        width: 100%;
        margin-bottom: 1.5rem;
      }
      .slideTitleContent {
        padding-left: 0;
      }
    }
    &.slick-slider {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  .SlickVerticalSlider {
    border: none;
    border-radius: 0;
    .sliderNav {
      display: none;
    }
    .sliderMain {
      width: 100%;
    }
  }
  // insight
  .pageInsightbContainer.sectionContainer {
    margin-top: 0;
    padding-top: 0;
  }
  .insight-section-title {
    font-size: 1rem;
  }
  .insight-section-paragraph {
    padding-bottom: 1rem;
  }

  // carousel
  .cloudSolution .solutionCon {
    width: 100%;
    margin-bottom: 2rem;
    padding: 1.5rem 1rem;
    img {
      height: 29px;
      margin-bottom: 1rem;
    }
    p {
      margin-bottom: 1rem;
    }
  }
  .resourcesContainer {
    width: 100%;
    img {
      height: auto;
    }
  }
  // banner
  section.project-hero {
    .inner {
      height: 50vh;
    }
    .featureValue,
    &.insightBanner .featureValue {
      font-size: 0.8rem;
    }
    &.insightBanner .project-info p {
      font-size: 1rem;
    }
    .bannerLogo {
      height: 32px;
    }
    &.imagedTitle {
      .inner::before {
        height: 105px;
      }
      .project-info .project-title {
        font-size: 1rem;
        margin-top: 0.1rem;
        padding-left: 0;
      }
    }
  }
  .titleCustomParagraph {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
    span {
      font-size: 1rem;
    }
    .titleParagraph {
      font-size: 0.9rem;
      font-weight: 600;
    }
  }
  .hoverContent,
  .hoverContent.threeColumn {
    width: 100%;
  }
  // footer
  footer.primary {
    .footer-cta .title {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    .footer-bottom .menu {
      margin-bottom: 0;
    }
  }
} // 767px

@media (max-width: 450px) {
  section.project-hero.view-detect.viewed .project-features {
    display: block;
    padding: 1.5rem;
  }
  // banner
  section.project-hero.heroPatch::before {
    width: 80%;
  }
  // Finance
  .whoWeAreSlide .hoverSlideUp {
    height: 430px;
  }
  // content blocks
  .service-box {
    width: 100%;
    .service-icon,
    .service-content {
      height: 195px;
    }
    .service-content {
      svg {
        height: 45px;
      }
      p {
        font-size: 0.9rem;
        line-height: 1.7;
      }
    }
  }
  .doCloud .cloudCon {
    height: 431px;
  }
}

.notFoundContaiermain {
  height: 100vh;
  width: 100vw;
}

.notFoundWrapper {
  display: flex;
  flex-direction: column;
  padding-top: 10rem;
}

.notfound-ContantContainer {
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 40px;
}

.notfound-contenttitle {
  font-weight: 800;
}

.notfound-contentbutton {
  background-color: $primary-color !important;
  width: fit-content;
  padding: 10px 40px !important;
  border-radius: 50px !important;
  font-weight: 700 !important;
}

@media (max-width: 1024px) {
  .notfound-ContantContainer {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .notfound-contenttitle {
    font-size: 2rem !important;
    margin-top: 2rem;
  }
  .notfound-contentbutton {
    font-size: 1rem !important;
  }
}
